import {Serializable} from './serializable.model';

export class TipoActuacion extends Serializable {
    categoria: string;
    subcategoria: string;
    categoriaSlug: string;
    subcategoriaSlug: string;
    selected: boolean = true;
    externa: boolean = false;
}
