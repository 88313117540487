import { Component, OnInit, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';

import {Actuacion} from '../actuacion.model';
import {ConfigService} from '../config.service';


import { Coordinate } from 'ol/coordinate';
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import {Icon, Style} from 'ol/style';
import * as proj from 'ol/proj';

@Component({
  selector: 'ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.css', '../../../../node_modules/ol/ol.css']
})
export class OlMapComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() width: string = '100%';
  @Input() height: string = '100%';

  @Input() center: Coordinate;
  @Input() zoom: number = 8;
  @Input() lng: number = -4.081421;
  @Input() lat: number = 43.209780;

  @Input() actuaciones: Array<Actuacion> = [];

  map: Map;
  vectorSource: VectorSource;
  overlay: Overlay;

  popupContent: { shown: boolean; actuacion: Actuacion; href: string};

  constructor(private configService: ConfigService) { 

    this.popupContent = {
      shown: false,
      actuacion: undefined,
      href: ''
    };

    this.vectorSource = new VectorSource({
      features: [],
    });

  }

  ngOnInit() {

    const vectorLayer = new VectorLayer({
      source: this.vectorSource,
    });

    this.map = new Map({
      view: new View({
        center: proj.fromLonLat([this.lng, this.lat]),
        zoom: this.zoom,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer
      ],
      target: 'ol-map'
    });

    const element = document.getElementById('popup');

    this.overlay = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
    });
    this.map.addOverlay(this.overlay);

    const self = this;
    
    var closer = document.getElementById('popup-closer');
    closer.onclick = function() {
      self.overlay.setPosition(undefined);
      return false;
    };

    const popContent = this.popupContent;

    this.map.on('click', function (evt) {
      const feature = evt.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      self.overlay.setPosition(undefined);
      if (!feature) {
        return;
      }

      popContent.shown = true;
		  popContent.actuacion = feature.get('actuacion');
      popContent.href = self.getActuacionHref(popContent.actuacion);

      var coordinate = evt.coordinate;
      self.overlay.setPosition(coordinate);
    });

  }

  ngAfterViewInit(): void {
    this.map.updateSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['actuaciones'] && this.vectorSource) {

      this.vectorSource.clear();

      if (this.overlay) {
        this.overlay.setPosition(undefined);
        this.popupContent.shown = false;
      }

      const chng = changes['actuaciones'];

      const previous: Array<Actuacion>  = chng.previousValue;
      const current: Array<Actuacion>  = chng.currentValue;

      current.forEach(actuacion => {

        const iconFeature = new Feature({
          geometry: new Point(proj.fromLonLat([actuacion.lng, actuacion.lat])),
          actuacion: actuacion
        });
        
        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: this.getMarkerIcon(actuacion),
          }),
        });
        
        iconFeature.setStyle(iconStyle);

        this.vectorSource.addFeature(iconFeature);

      });
    }
  }

  getMarkerIcon(actuacion: Actuacion): string {
    if (actuacion && actuacion.externa) {
      return this.configService.getAssetsUrl('images/pin-amarillo.png');
    } else if (actuacion && actuacion.categoria === 'voluntariado-ambiental') {
        return this.configService.getAssetsUrl('images/pin-morado.png');
    } else if (actuacion && actuacion.categoria === 'educacion-ambiental') {
      return this.configService.getAssetsUrl('images/pin-azul.png');
    }  else if (actuacion && actuacion.categoria === 'externa') {
      return this.configService.getAssetsUrl('images/pin-amarillo.png');
    } else {
        return this.configService.getAssetsUrl('images/pin-verde.png');
    }
  }

  getActuacionHref(actuacion: Actuacion): string {
    return this.configService.getHrefUrl("-/provoca/actuacion/" + actuacion.actuacionId);
  }
}
