import {Serializable} from './serializable.model';

export class Actuacion extends Serializable {
    actuacionId: number;
    codMuni: number;
    titulo: string;
    entidad: string;
    localizacion: string;
    municipio: string;
    lat: number;
    lng: number;
    fechaInicio: number;
    fechaFinal: number;
    categorias: string;
    cupo: number;
    ocupacion: number;
    categoria: string;
    subcategoria: string;
    externa: boolean;
    visiblePorFecha: boolean = true;
    visiblePorFiltro: boolean = true;
    visiblePorGrupo: boolean = true;
    visiblePorMes: boolean = true;
    
    get visible(): boolean {
        return this.visiblePorFecha && this.visiblePorFiltro && this.visiblePorGrupo;
    }

    get visibleMes(): boolean {
        return this.visiblePorMes && this.visiblePorFiltro && this.visiblePorGrupo;
    }
}
