import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Actuacion } from '../actuacion.model';
import { TipoActuacion } from '../tipo.actuacion.model';

import { DataService } from '../data.service';
import { ConfigService } from '../config.service';

@Component({
    selector: 'provoca-calendar',
    templateUrl: './provoca-calendar.component.html',
    styleUrls: ['./provoca-calendar.component.css']
})

export class ProvocaCalendarComponent implements OnInit, OnChanges {
    @Input() actuaciones: Array<Actuacion>;
    @Input() year: number;
    @Output() actuacionesDay = new EventEmitter();
    @Output() actuacionesMonth = new EventEmitter();

    month: number;

    actuacionesProcesadas: Map<string, Array<Actuacion>> = null;
    selectedActuaciones: Array<Actuacion> = []
    yearProcesado: number = null;
    selectedDay: number = null
    selectedMonth: number = null;
    calendar: number[][] = null
    months: Array<string> = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre",
        "Diciembre"]
    tiposActuacion: Map<String, TipoActuacion> = new Map();

    constructor(private configService: ConfigService, private dataService: DataService) {
        dataService.getTiposActuacion().subscribe(tipos => {
            for (let tipo of tipos) {
                this.tiposActuacion.set(tipo.categoriaSlug + "/" + tipo.subcategoriaSlug, tipo);
            }
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['actuaciones']) {
            const chng = changes['actuaciones'];

            const previous: Array<Actuacion> = chng.previousValue;
            const current: Array<Actuacion> = chng.currentValue;

            this.actuaciones = current;
        }

        if (typeof this.month === 'undefined' || this.month == null) {
            var now = new Date();
            now.setFullYear(this.year);
            this.month = now.getMonth();
            this.checkMonth(this.month);
        }
        //this.selectedDay = null;
        //this.selectedActuaciones = [];
        this.processActuaciones();
    }

    processActuaciones() {
        var date: Date;
        var fecha_procesada: string;
        this.actuacionesProcesadas = new Map();
        for (let actuacion of this.actuaciones) {
            date = new Date(actuacion.fechaInicio);
            fecha_procesada = date.getFullYear() + "-" + date.getMonth() + "-" +
                date.getDate();
            var actsDia = this.actuacionesProcesadas.get(fecha_procesada);
            if (!actsDia) {
                actsDia = [];
                this.actuacionesProcesadas.set(fecha_procesada, actsDia);
            }
            actsDia.push(actuacion);
        }
        this.yearProcesado = this.year;
        this.generateMonth();
    }

    generateMonth() {
        if (!this.year) {
            return;
        }
        this.calendar = [];
        var currentDate = new Date(this.year, this.month, 1);

        var weekDay = currentDate.getDay() - 1;
        if (weekDay < 0) {
            weekDay = 6;
        }

        var totalDays = this.getTotalDays(currentDate);
        var calendarRow: number[] = [null, null, null, null, null, null, null];
        for (let day = 1; day <= totalDays; day++) {
            calendarRow[weekDay++] = day;
            if (weekDay > 6 || day == totalDays) {
                weekDay = 0;
                this.calendar.push(calendarRow);
                calendarRow = [];
            }
        }
    }

    prevMonth() {
        if (this.month > 0) {
            this.month--;
            this.generateMonth();
            this.checkMonth(this.month);
        }
    }

    nextMonth() {
        if (this.month < 11) {
            this.month++;
            this.generateMonth();
            this.checkMonth(this.month);
        }
    }

    getTotalDays(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    hasActuacion(day: number): boolean {
        if (!day || !this.actuacionesProcesadas || !this.actuacionesProcesadas.size) {
            return false;
        }
        var acts = this.getActuaciones(day);
        if (!acts || !acts.length) {
            return false;
        }
        for (let act of acts) {
            if (act.visiblePorFiltro) {
                return true;
            }
        }
        return false;
    }

    getActuaciones(day: number): Array<Actuacion> {
        var fecha = this.year + "-" + this.month + "-" + day;
        return this.actuacionesProcesadas.get(fecha) || [];
    }

    checkDay(day: number) {
        // var day = parseInt(dayStr);
        if (day == this.selectedDay) {
            this.actuacionesDay.emit(null);
            this.selectedDay = null;
            this.selectedMonth = null;
        } else {
            this.selectedDay = day;
            this.selectedMonth = this.month;
            this.selectedActuaciones = this.getActuaciones(day);
            this.actuacionesDay.emit(day);
        }
    }

    clickDay(day: number) {
        // var day = parseInt(dayStr);
        if (day == this.selectedDay) {
            //console.log("Deseleccionando día " + day);
            this.actuacionesDay.emit(null);
            this.selectedDay = null;
            this.selectedMonth = null;
        } else {
            var acts = this.getActuaciones(day);
            if (acts && acts.length) {
                //console.log("Seleccionando día " + day);
                this.selectedDay = day;
                this.selectedMonth = this.month;
                this.selectedActuaciones = acts;
                this.actuacionesDay.emit(day);
            } else {
                //console.log("No hay actuaciones para el día " + day);
                this.actuacionesDay.emit(null);
                this.selectedDay = null;
                this.selectedMonth = null;
            }
        }
    }

    checkMonth(month: number) {
        if (month == this.selectedMonth) {
            this.selectedDay = null;
            this.selectedMonth = null;
            this.actuacionesMonth.emit(null);
        } else {
            this.selectedDay = null;
            this.month = month;
            this.selectedMonth = this.month;
            this.actuacionesMonth.emit(this.month);
        }
    }

    getHours(fecha_inicio: number, fecha_final: number) {
        var inicio = new Date(fecha_inicio)
        var final = new Date(fecha_final)
        var inicioMinutes: string = inicio.getMinutes() < 10
            ? '0' + inicio.getMinutes()
            : String(inicio.getMinutes());
        var finalMinutes: string = final.getMinutes() < 10
            ? '0' + final.getMinutes()
            : String(final.getMinutes());
        return inicio.getHours() + ":" + inicioMinutes + " - "
            + final.getHours() + ":" + finalMinutes;
    }

    getCurrentMonthName() {
        if (typeof this.month === 'undefined' || this.month === null) {
            return "";
        }
        return this.months[this.month];
    }

    getSelectedDate(): Date {
        if (!this.year || !this.month || !this.selectedDay) {
            return null;
        }
        return new Date(this.year, this.month, this.selectedDay);
    }

    getHref(actuacion: Actuacion) {
        return this.configService.getHrefUrl("-/provoca/actuacion/" + actuacion.actuacionId);
    }

    goToActuacion(actuacion: Actuacion) {
        window.open(this.getHref(actuacion));
    }

    getTipoActuacion(actuacion: Actuacion) {
        return this.tiposActuacion.get(actuacion.categoria + '/' + actuacion.subcategoria);
    }

    filtersChanged(): Array<Actuacion> {
        if (this.selectedDay) {
            if (!this.hasActuacion(this.selectedDay)) {
                this.selectedDay = null;
                this.selectedActuaciones = [];
                return null;
            }
            return this.selectedActuaciones;
        }
        return null;
    }
}

