import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {Actuacion} from '../actuacion.model';
import {TipoActuacion} from '../tipo.actuacion.model';
import {Municipio} from '../municipio.model';

import {DataService} from '../data.service';

@Component({
    selector: 'provoca-filters',
    templateUrl: './provoca-filters.component.html',
    styleUrls: ['./provoca-filters.component.css']
})

export class ProvocaFiltersComponent implements OnInit {
    
    @Output() selectedYear = new EventEmitter();
    @Output() activeCategories = new EventEmitter();
    @Output() activeEntities = new EventEmitter();
    @Output() activeMunicipios = new EventEmitter();
    @Output() activeGrupo = new EventEmitter();

    year: number;
    years: Array<number>;
    tiposHijo: Map<String, Array<TipoActuacion>> = new Map();
    tiposPadre: Array<TipoActuacion> = [];
    grupo: string;
    
    _entities: Array<String>;
    selectedEntities: Set<String>;
    
    municipios: Array<Municipio>;
    selectedMunicipios: Set<Municipio>;
    
    constructor(private dataService: DataService) {
        dataService.getYears().subscribe(years => {
            this.years = years.slice().reverse();
            if (this.years.length) {
                var thisYear = (new Date()).getFullYear();
                if (this.years.indexOf(thisYear) !== -1) {
                    this.year = thisYear;
                } else {
                    this.year = this.years[0];
                }
            }
        });
        dataService.getTiposActuacion().subscribe(tipos => {
            for (let tipo of tipos) {
                tipo.selected = true;
                if (!tipo.subcategoriaSlug || tipo.categoriaSlug == tipo.subcategoriaSlug) {
                    // Padre
                    this.tiposPadre.push(tipo);
                } else {
                    // Hijo
                    var tipos = this.tiposHijo.get(tipo.categoriaSlug);
                    if (!tipos) {
                        tipos = [];
                        this.tiposHijo.set(tipo.categoriaSlug, tipos);
                    }
                    tipos.push(tipo);
                }
            }
        });
        this.municipios = dataService.municipios;
        this.selectedMunicipios = new Set(this.municipios);
        this.grupo = "todas";
    }
    
    @Input()
    set entities(entities: Array<String>) {
        this._entities = entities;
        this.selectedEntities = new Set(entities);
    }
    
    get entities(): Array<String> {
        return this._entities;
    }
    
    yearChange(newYear: number) {
        this.selectedYear.emit(newYear);
    }
    
    ngOnInit() {
        
    }
    
    toggleCategory(tipoActuacion: TipoActuacion) {
        tipoActuacion.selected = !tipoActuacion.selected;
        var hijos = this.tiposHijo.get(tipoActuacion.categoriaSlug);
        if (hijos) {
            for (let tipo of hijos) {
                tipo.selected = tipoActuacion.selected;
            }
        }
        this.emitCategories();
    }
    
    toggleSubcategory(tipoActuacion: TipoActuacion) {
        tipoActuacion.selected = !tipoActuacion.selected;
        this.emitCategories();
    }
    
    toggleEntity(entity: String = null) {
        if (!entity) {
            if (this.selectedEntities.size) {
                this.selectedEntities.clear();
            } else {
                this.selectedEntities = new Set(this.entities);
            }
        } else {
            if (this.selectedEntities.has(entity)) {
                this.selectedEntities['delete'](entity);
            } else {
                this.selectedEntities.add(entity);
            }
        }
        this.activeEntities.emit(this.selectedEntities);
    }
    
    emitCategories() {
        var selectedCategories: Array<String> = [];
        for (let padre of this.tiposPadre) {
            if (padre.selected) {
                selectedCategories.push(padre.categoriaSlug + "/" + padre.subcategoriaSlug);
            }
            var hijos = this.tiposHijo.get(padre.categoriaSlug);
            if (hijos) {
                for (let hijo of hijos) {
                    if (hijo.selected) {
                        selectedCategories.push(hijo.categoriaSlug + "/" + hijo.subcategoriaSlug);
                    }
                }
            }
        }
        this.activeCategories.emit(selectedCategories);
    }
    
    toggleMunicipio(municipio: Municipio = null) {
        if (!municipio) {
            if (this.selectedMunicipios.size) {
                this.selectedMunicipios.clear();
            } else {
                this.selectedMunicipios = new Set(this.municipios);
            }
        } else {
            if (this.selectedMunicipios.has(municipio)) {
                this.selectedMunicipios['delete'](municipio);
            } else {
                this.selectedMunicipios.add(municipio);
            }
        }
        this.activeMunicipios.emit(this.selectedMunicipios);
    }

    grupoChange(grupo: string) {
        this.activeGrupo.emit(grupo);
    }
}
