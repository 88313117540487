// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    display: block;
}

:host:after {
    display: block;
    content: "";
    clear: both;
}

.categorias,
.categoria,
.entidades,
.municipios,
.anyo {
    float: left;
}

.otros-filtros {
    float: right;
}

.categoria,
.entidades,
.municipios {
    padding: 0.2em 0.5em;
    text-transform: uppercase;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding-bottom: 0.3em;
}

.categoria.subcats-0 {
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.categoria {
    color: #23a389;
    position: relative;
}

.categoria-general {
    color: #23a389;
}

.cat-voluntariado-ambiental {
    color: #cc31d2;
}

.cat-educacion-ambiental {
    color: #4367cf;
}

.cat-externa {
    color: #a18f03;
}

.subcategorias,
.entidades ul,
.municipios ul {
    position: absolute;
    display: none;
    margin: 0.3em 0 0 0;
    padding: 0.3em 0.5em 0.5em;
    background: inherit;
    list-style: none;
    z-index: 99;
}

.subcategorias {
    left: 0;
    width: 150%;
}

ul li {
    padding-top: 1px;
    padding-bottom: 1px;
    page-break-inside: avoid;
    break-inside: avoid;
}

.entidades ul {
    column-count: 3;
    right: 0;
    min-width: 20em;
}

.entidades ul.items-20 {
    column-count: 2;
}

.entidades ul.items-10 {
    column-count: 1;
}

.municipios ul {
    column-count: 4;
    left: 5em;
}

.categoria:hover {
    color: white;
    background: #23a389;
}

.cat-voluntariado-ambiental:hover {
    background: #cc31d2;
}

.cat-educacion-ambiental:hover {
    background: #4367cf;
}

.municipios:hover,
.entidades:hover {
    color: white;
    background: #888;
}

.categoria:hover .subcategorias,
.entidades:hover ul,
.municipios:hover ul {
    display: block;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.entidades:hover ul,
.municipios:hover ul {
    border-top-left-radius: 6px;
}

ul li label {
    display: block;
    position: relative;
    padding-left: 2em;
}

ul li label input {
    position: absolute;
    left: 0.3em;
}

.anyo {
    padding: 0.2em 0.5em;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
