import {Component, OnInit, Input, Output, ViewChild} from '@angular/core';

import {Actuacion} from './shared/actuacion.model';
import {Municipio} from "./shared/municipio.model";

import {DataService} from './shared/data.service';
import {ConfigService} from './shared/config.service';
import {ProvocaCalendarComponent} from './shared/provoca-calendar/provoca-calendar.component';
import { toStringHDMS } from 'ol/coordinate';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    @Input() lat : number = 43.209780;
    @Input() lng : number = -4.081421;
    @Input() zoom : number = 9;
    @Input() mapHeight : string = "480px";
    @Input() mapWidth : string = "100%";
    opacity = 0.5;

    year: number = 2024;
    actuaciones: Array<Actuacion> = [];
    actuacionesVisibles: Array<Actuacion> = [];
    actuacionesVisiblesMes: Array<Actuacion> = [];
    loading = true;
    month: number | null;
    day: number | null;
    group: string = "todas";

    currentCoords: any = null;
    
    popupContent: { shown: boolean; actuacion: Actuacion};

    viewOptions = {
        displayCoords: false,
        displayMarkedAddresses: false
    };

    lastInfoWindow: any;
    
    years: Array<number>;
    
    selectedCategories: Set<String>;
    
    entidades: Array<String>;
    selectedEntidades: Set<String>;
    
    selectedMunicipios: Set<number>;

    selectedGrupo: string;
        
    @ViewChild(ProvocaCalendarComponent, {static: false})
    private calendarComponent: ProvocaCalendarComponent;
    
    constructor(private dataService: DataService, private configService: ConfigService) {
         this.popupContent = {
	      shown: false,
	      actuacion: undefined
	    };
        dataService.getYears().subscribe(years => {
            this.years = years;
            if (this.years.length) {
                this.years.sort();
                var thisYear = (new Date()).getFullYear();
                if (this.years.indexOf(thisYear) !== -1) {
                    this.changeYear(thisYear);
                } else {
                    this.changeYear(this.years[this.years.length - 1]);
                }
            }
        });
    }

    ngOnInit() {
    }
    
    updateEntidades() {
        var entidades = new Set<String>();
        for (let actuacion of this.actuaciones) {
            entidades.add(actuacion.entidad);
        }
        this.entidades = [];
        entidades.forEach(entidad => this.entidades.push(entidad));
    }

    markerClick(infoWindow: any) {
        if (this.lastInfoWindow) {
            try {
                if (this.lastInfoWindow)
                    this.lastInfoWindow.close();
            } catch (err) {
                // Ignore
            }
        }
        this.lastInfoWindow = infoWindow;
    }
    
    updateVisibleActuaciones() {
        this.actuacionesVisibles = [];
        this.actuacionesVisiblesMes = [];
        for (let actuacion of this.actuaciones) {
            
            actuacion.visiblePorFecha = true;
            actuacion.visiblePorMes = true;
            let final = new Date(actuacion.fechaFinal);
            let inicio = new Date(actuacion.fechaInicio);
            let finalMonth = final.getMonth();
            let inicioMonth = inicio.getMonth();
            let inicioDay = inicio.getDate();
            if (this.month !== undefined && this.month !== null
                && (this.month < inicioMonth || this.month > finalMonth)) {
                actuacion.visiblePorFecha = false;
                actuacion.visiblePorMes = false;
            }
            if (this.day !== undefined && this.day !== null && (this.day != inicioDay)) {
                actuacion.visiblePorFecha = false;
            }
            
            actuacion.visiblePorFiltro = true;
            if (this.selectedCategories && actuacion.categoria && actuacion.subcategoria) {
                actuacion.visiblePorFiltro = actuacion.visiblePorFiltro && 
                    this.selectedCategories.has(
                        actuacion.categoria + '/' + actuacion.subcategoria
                    );
            }
            if (this.selectedEntidades && actuacion.entidad) {
                actuacion.visiblePorFiltro = actuacion.visiblePorFiltro &&
                    this.selectedEntidades.has(actuacion.entidad);
            }
            if (this.selectedMunicipios && actuacion.codMuni) {
                actuacion.visiblePorFiltro = actuacion.visiblePorFiltro &&
                    this.selectedMunicipios.has(actuacion.codMuni);
            }

            actuacion.visiblePorGrupo = true;
            if (this.selectedGrupo) {
                actuacion.visiblePorGrupo = this.selectedGrupo === "todas" || 
                    (this.selectedGrupo === "internas" && (!actuacion.externa)) ||
                    (this.selectedGrupo === "externas" && actuacion.externa === true);
            }


            if (actuacion.visiblePorFiltro && actuacion.visiblePorFecha && actuacion.visiblePorGrupo) {
                this.actuacionesVisibles.push(actuacion);
            }
            if (actuacion.visiblePorFiltro && actuacion.visiblePorMes && actuacion.visiblePorGrupo) {
                this.actuacionesVisiblesMes.push(actuacion);
            }
        }
    }
    
    getActuacionHref(actuacion: Actuacion) {
        return this.configService.getHrefUrl("-/provoca/actuacion/" + actuacion.actuacionId);
    }
    
    goToActuacion(actuacion: Actuacion) {
        location.href = this.getActuacionHref(actuacion);
    }
        
    changeYear(newYear: number) {
        this.year = newYear; //  parseInt(newYear);
        this.day = null;
        this.dataService.getActuaciones(this.year)
            .subscribe(actuaciones => {
                this.actuaciones = actuaciones;
                this.updateEntidades();
                this.updateVisibleActuaciones();
        });
    }
    
    changeCategories(newCategories: Array<String>) {
        this.selectedCategories = new Set(newCategories);
        this.updateVisibleActuaciones();
        this.calendarComponent.filtersChanged();
    }
    
    changeEntities(newEntities: Array<String>) {
        this.selectedEntidades = new Set(newEntities);
        this.updateVisibleActuaciones();
        this.calendarComponent.filtersChanged();
    }
    
    changeMunicipios(newMunicipios: Array<Municipio>) {
        this.selectedMunicipios = new Set();
        newMunicipios.forEach(m => this.selectedMunicipios.add(m.codigo));
        this.updateVisibleActuaciones();
        this.calendarComponent.filtersChanged();
    }

    changeGrupo(newGroup: string) {
        this.selectedGrupo = newGroup;
        this.updateVisibleActuaciones();
        this.calendarComponent.filtersChanged();
    }
    
    getMarkerIcon(actuacion: Actuacion) {
        if (actuacion && actuacion.categoria === 'voluntariado-ambiental') {
            return this.configService.getAssetsUrl('images/pin-morado.png');
        } else if (actuacion && actuacion.categoria === 'educacion-ambiental') {
            return this.configService.getAssetsUrl('images/pin-azul.png');
        } else if (actuacion && actuacion.externa) {
            return this.configService.getAssetsUrl('images/pin-amarillo.png');
        } else {
            return this.configService.getAssetsUrl('images/pin-verde.png');
        }
    }
    
    changeCalendarDay(day: number, update = true) {
        this.day = day;
        if (update) {
            this.updateVisibleActuaciones();
        }
    }
    
    changeCalendarMonth(month: number, update = true) {
        if (month !== undefined && month !== null) {
            this.month = month;
            this.day = null;
        }

        if (update) {
            this.updateVisibleActuaciones();
        }
    }
	
	public setPopupContent(actuacion: Actuacion) {
	    if (actuacion) {
		    this.popupContent.shown = true;
		    this.popupContent.actuacion = actuacion;
	    }
	}    

}
