import {Injectable} from '@angular/core';

@Injectable()
export class ConfigService {
    
    private apiUrl: string;
    private hrefUrl: string;
    private assetsUrl: string;
    private companyId: number;
    private tipoActuacionId: number;
    
    constructor() {
        this.apiUrl = window['ProvocaConfig']['apiUrl'];
        if (!this.apiUrl.endsWith("/")) {
            this.apiUrl += '/';
        }
        this.hrefUrl = window['ProvocaConfig']['hrefUrl']
        if (!this.hrefUrl.endsWith("/")) {
            this.hrefUrl += '/';
        }
        this.assetsUrl = window['ProvocaConfig']['assetsUrl']
        if (!this.assetsUrl.endsWith("/")) {
            this.assetsUrl += '/';
        }
        this.companyId = window['ProvocaConfig']['companyId'] || null;
        this.tipoActuacionId = window['ProvocaConfig']['tipoActuacionId'] || 0;
    }
    
    getApiUrl(path = ''): string {
        return this.apiUrl + path;
    }
    
    getHrefUrl(path = ''): string {
        return this.hrefUrl + path;
    }
    
    getAssetsUrl(path = ''): string {
        return this.assetsUrl + path;
    }
    
    getCompanyId(): number {
        return this.companyId;
    }
    
    getTipoActuacionId(): number {
        return this.tipoActuacionId;
    }
}