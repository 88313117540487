import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import { map, share } from 'rxjs/operators';

import {Actuacion} from './actuacion.model'
import {ConfigService} from './config.service'
import {TipoActuacion} from "./tipo.actuacion.model";
import {Municipio} from './municipio.model';

@Injectable()
export class DataService {

    private yearsObservable: Observable<Array<number>>;
    private years: Array<number>;
    private tiposActuacionObservable: Observable<Array<TipoActuacion>>;
    private tiposActuacion: Array<TipoActuacion> = null;
    private grupoActuacionObservable: Observable<string>;
    private grupoActuacion: string = "todas";
    
    private actuaciones: Map<number, Actuacion[]> = new Map()
    private tipoActuacionId: number;
    private companyId: number;
    
    public municipiosMap: Map<number, string>;
    public municipios: Array<Municipio>;

    constructor(private http: HttpClient, private configService: ConfigService) {
        var params = new HttpParams();
        if (this.companyId) {
            params = params.set("companyId", String(this.companyId));
        }
        this.yearsObservable = this.http.get(configService.getApiUrl("anyos"), {
                params: params
            }).pipe(map(res => this.years = res as Array<number>)),share();
        this.tiposActuacionObservable = this.http.get(configService.getApiUrl("tipos-actuacion"))
            .pipe(map(res => {
                this.tiposActuacion = [];
                for (let tipo of res as Array<any>) {
                    this.tiposActuacion.push(new TipoActuacion().fromJSON(tipo));
                }
                return this.tiposActuacion;
            })),share();
        this.tipoActuacionId = configService.getTipoActuacionId();
        this.companyId = configService.getCompanyId();
        // Municipios
        this.initMunicipios();
    }        

    getYears(): Observable<Array<number>> {
        if (this.years) {
            return of(this.years);
        }
        return this.yearsObservable;
    }
    
    getActuaciones(year: number): Observable<Actuacion[]> {
        if (this.actuaciones.has(year)) {
            return of(this.actuaciones.get(year));
        }
        
        var params = new HttpParams().set("anyo", String(year))
            .set("tipoActuacionId", String(this.tipoActuacionId));
            
        if (this.companyId) {
            params = params.set("companyId", String(this.companyId));
        }
        
        return this.http.get(this.configService.getApiUrl("actuaciones"), {
                params: params
            })
            .pipe(map(res => {
                var acts: Array<Actuacion> = [];
                for (let jsonAct of res as Array<Actuacion>) {
                    acts.push(new Actuacion().fromJSON(jsonAct));
                }
                this.actuaciones.set(year, acts);
                return acts;
            }));
            //,share();
    }
    
    getTiposActuacion(): Observable<Array<TipoActuacion>> {
        if (this.tiposActuacion) {
            return of(this.tiposActuacion);
        }
        return this.tiposActuacionObservable;
    }

    getGrupoActuacion(): Observable<string> {
        if (this.grupoActuacion) {
            return of(this.grupoActuacion);
        }
        return this.grupoActuacionObservable;
    }
    
    initMunicipios() {
        this.municipios = [];
        this.municipios.push({codigo: 39001, nombre: "Alfoz de Lloredo"});
        this.municipios.push({codigo: 39003, nombre: "Anievas"});
        this.municipios.push({codigo: 39002, nombre: "Ampuero"});
        this.municipios.push({codigo: 39004, nombre: "Arenas de Iguña"});
        this.municipios.push({codigo: 39005, nombre: "Argoños"});
        this.municipios.push({codigo: 39006, nombre: "Arnuero"});
        this.municipios.push({codigo: 39007, nombre: "Arredondo"});
        this.municipios.push({codigo: 39008, nombre: "Astillero (El)"});
        this.municipios.push({codigo: 39009, nombre: "Bárcena de Cicero"});
        this.municipios.push({codigo: 39010, nombre: "Bárcena de Pie de Concha"});
        this.municipios.push({codigo: 39011, nombre: "Bareyo"});
        this.municipios.push({codigo: 39012, nombre: "Cabezón de la Sal"});
        this.municipios.push({codigo: 39013, nombre: "Cabezón de Liébana"});
        this.municipios.push({codigo: 39014, nombre: "Cabuérniga"});
        this.municipios.push({codigo: 39015, nombre: "Camaleño"});
        this.municipios.push({codigo: 39016, nombre: "Camargo"});
        this.municipios.push({codigo: 39027, nombre: "Campoo de Enmedio"});
        this.municipios.push({codigo: 39017, nombre: "Campoo de Yuso"});
        this.municipios.push({codigo: 39018, nombre: "Cartes"});
        this.municipios.push({codigo: 39019, nombre: "Castañeda"});
        this.municipios.push({codigo: 39020, nombre: "Castro-Urdiales"});
        this.municipios.push({codigo: 39021, nombre: "Cieza"});
        this.municipios.push({codigo: 39022, nombre: "Cillorigo de Liébana"});
        this.municipios.push({codigo: 39023, nombre: "Colindres"});
        this.municipios.push({codigo: 39024, nombre: "Comillas"});
        this.municipios.push({codigo: 39025, nombre: "Corrales de Buelna (Los)"});
        this.municipios.push({codigo: 39026, nombre: "Corvera de Toranzo"});
        this.municipios.push({codigo: 39028, nombre: "Entrambasaguas"});
        this.municipios.push({codigo: 39029, nombre: "Escalante"});
        this.municipios.push({codigo: 39030, nombre: "Guriezo"});
        this.municipios.push({codigo: 39031, nombre: "Hazas de Cesto"});
        this.municipios.push({codigo: 39032, nombre: "Hermandad de Campoo de Suso"});
        this.municipios.push({codigo: 39033, nombre: "Herrerías"});
        this.municipios.push({codigo: 39034, nombre: "Lamasón"});
        this.municipios.push({codigo: 39035, nombre: "Laredo"});
        this.municipios.push({codigo: 39036, nombre: "Liendo"});
        this.municipios.push({codigo: 39037, nombre: "Liérganes"});
        this.municipios.push({codigo: 39038, nombre: "Limpias"});
        this.municipios.push({codigo: 39039, nombre: "Luena"});
        this.municipios.push({codigo: 39040, nombre: "Marina de Cudeyo"});
        this.municipios.push({codigo: 39041, nombre: "Mazcuerras"});
        this.municipios.push({codigo: 39042, nombre: "Medio Cudeyo"});
        this.municipios.push({codigo: 39043, nombre: "Meruelo"});
        this.municipios.push({codigo: 39044, nombre: "Miengo"});
        this.municipios.push({codigo: 39045, nombre: "Miera"});
        this.municipios.push({codigo: 39046, nombre: "Molledo"});
        this.municipios.push({codigo: 39047, nombre: "Noja"});
        this.municipios.push({codigo: 39048, nombre: "Penagos"});
        this.municipios.push({codigo: 39049, nombre: "Peñarrubia"});
        this.municipios.push({codigo: 39050, nombre: "Pesaguero"});
        this.municipios.push({codigo: 39051, nombre: "Pesquera"});
        this.municipios.push({codigo: 39052, nombre: "Piélagos"});
        this.municipios.push({codigo: 39053, nombre: "Polaciones"});
        this.municipios.push({codigo: 39054, nombre: "Polanco"});
        this.municipios.push({codigo: 39055, nombre: "Potes"});
        this.municipios.push({codigo: 39056, nombre: "Puente Viesgo"});
        this.municipios.push({codigo: 39057, nombre: "Ramales de la Victoria"});
        this.municipios.push({codigo: 39058, nombre: "Rasines"});
        this.municipios.push({codigo: 39059, nombre: "Reinosa"});
        this.municipios.push({codigo: 39060, nombre: "Reocín"});
        this.municipios.push({codigo: 39061, nombre: "Ribamontán al Mar"});
        this.municipios.push({codigo: 39062, nombre: "Ribamontán al Monte"});
        this.municipios.push({codigo: 39063, nombre: "Rionansa"});
        this.municipios.push({codigo: 39064, nombre: "Riotuerto"});
        this.municipios.push({codigo: 39065, nombre: "Rozas de Valdearroyo (Las)"});
        this.municipios.push({codigo: 39066, nombre: "Ruente"});
        this.municipios.push({codigo: 39067, nombre: "Ruesga"});
        this.municipios.push({codigo: 39068, nombre: "Ruiloba"});
        this.municipios.push({codigo: 39069, nombre: "San Felices de Buelna"});
        this.municipios.push({codigo: 39070, nombre: "San Miguel de Aguayo"});
        this.municipios.push({codigo: 39071, nombre: "San Pedro del Romeral"});
        this.municipios.push({codigo: 39072, nombre: "San Roque de Riomiera"});
        this.municipios.push({codigo: 39080, nombre: "San Vicente de la Barquera"});
        this.municipios.push({codigo: 39073, nombre: "Santa Cruz de Bezana"});
        this.municipios.push({codigo: 39074, nombre: "Santa María de Cayón"});
        this.municipios.push({codigo: 39075, nombre: "Santander"});
        this.municipios.push({codigo: 39076, nombre: "Santillana del Mar"});
        this.municipios.push({codigo: 39077, nombre: "Santiurde de Reinosa"});
        this.municipios.push({codigo: 39078, nombre: "Santiurde de Toranzo"});
        this.municipios.push({codigo: 39079, nombre: "Santoña"});
        this.municipios.push({codigo: 39081, nombre: "Saro"});
        this.municipios.push({codigo: 39082, nombre: "Selaya"});
        this.municipios.push({codigo: 39083, nombre: "Soba"});
        this.municipios.push({codigo: 39084, nombre: "Solórzano"});
        this.municipios.push({codigo: 39085, nombre: "Suances"});
        this.municipios.push({codigo: 39086, nombre: "Tojos (Los)"});
        this.municipios.push({codigo: 39087, nombre: "Torrelavega"});
        this.municipios.push({codigo: 39088, nombre: "Tresviso"});
        this.municipios.push({codigo: 39089, nombre: "Tudanca"});
        this.municipios.push({codigo: 39090, nombre: "Udías"});
        this.municipios.push({codigo: 39095, nombre: "Val de San Vicente"});
        this.municipios.push({codigo: 39091, nombre: "Valdáliga"});
        this.municipios.push({codigo: 39092, nombre: "Valdeolea"});
        this.municipios.push({codigo: 39093, nombre: "Valdeprado del Río"});
        this.municipios.push({codigo: 39094, nombre: "Valderredible"});
        this.municipios.push({codigo: 39101, nombre: "Valle de Villaverde"});
        this.municipios.push({codigo: 39096, nombre: "Vega de Liébana"});
        this.municipios.push({codigo: 39097, nombre: "Vega de Pas"});
        this.municipios.push({codigo: 39098, nombre: "Villacarriedo"});
        this.municipios.push({codigo: 39099, nombre: "Villaescusa"});
        this.municipios.push({codigo: 39100, nombre: "Villafufre"});
        this.municipios.push({codigo: 39102, nombre: "Voto"});
        
        this.municipiosMap = new Map();
        this.municipios.forEach(m => this.municipiosMap.set(m.codigo, m.nombre));
    }
}