import {BrowserModule} from '@angular/platform-browser';
import {Directive, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {ProvocaCalendarComponent} from './shared/provoca-calendar/provoca-calendar.component';
import {ProvocaFiltersComponent} from './shared/provoca-filters/provoca-filters.component';
import {OlMapComponent} from './shared/ol-map/ol-map.component';
import {DataService} from './shared/data.service';
import {ConfigService} from './shared/config.service';

@NgModule({
    declarations: [
        AppComponent, ProvocaCalendarComponent, ProvocaFiltersComponent, OlMapComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule
    ],
    providers: [
        DataService, 
        ConfigService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
